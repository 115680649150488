
import axios from "axios";
import { defineComponent } from "vue";
import { dateStandard } from "../components/generic.helper";
import { blog } from "./Home.vue";
import marked from "marked";
import Loader from "../components/Loader.vue";

declare const MathJax: any;

export default defineComponent({
  name: "Blog",
  components: {
    Loader,
  },
  data() {
    return {
      blog: {} as blog & { Content: string },
      blogURL: "",
      requesting: false,
    };
  },
  methods: {
    async getData() {
      try {
        this.requesting = true;
        const res = await axios.post("/api/graphql", {
          query: `{
              blogs(limit: 1, start: 0, where: {url: "${this.blogURL}"}){
                id,
                Title,
                Content,
                url,
                Author,
                PublishDate,
                tags {
                    TagName,
                    TagURL
                },
                categories {
                    CategoryName,
                    CategoryURL
                }
            }
          }`,
        });
        this.blog = res.data.data.blogs[0] ?? null;
        if (this.blog) {
          this.blog.PublishDate = dateStandard(new Date(this.blog.PublishDate));
          this.blog.Content = marked(this.blog.Content);
          document.title = this.blog.Title + " | Reyify";
        }
        MathJax.typesetPromise();
        this.requesting = false;
      } catch (e) {
        console.log("err", e);
      }
    },
  },
  mounted() {
    this.blogURL = this.$route.params.blogURL.toString();
    this.getData();
  },
  updated() {
    this.$nextTick(function () {
      MathJax.typesetPromise();
      let headers = document.querySelectorAll("h1, h2, h3, h4, h5, h6");
      let i = -1;
      for (const header of headers) {
        i++;
        if (i < 1) continue;
        let id =
          header.getAttribute("id") ||
          (header as HTMLHeadingElement).innerText
            .toLowerCase()
            .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>/]/gi, "")
            .replace(/ +/g, "-");
        header.setAttribute("id", id);
        header.innerHTML =
          `<a href="#${id}" aria-hidden="true" class="aal_anchor"><svg aria-hidden="true" class="aal_svg" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fill-rule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>` +
          header.innerHTML;
      }
    });
  },
});
