
import { defineComponent } from "vue";
declare let ResizeObserver: any;

export default defineComponent({
  name: "Footer",
  components: {},
  data() {
    return {
      isFixed: false,
    };
  },
  methods: {
    checkFixed() {
      this.isFixed =
        window.innerHeight >
        (document.getElementById("main")?.offsetHeight ?? 0) + 100;
    },
  },
  mounted() {
    this.checkFixed();
    window.addEventListener("resize", () => {
      this.checkFixed();
    });
    const resizeObserver = new ResizeObserver((entries: any) => {
      this.checkFixed();
    });
    resizeObserver.observe(document.getElementById("main"));
  },
});
