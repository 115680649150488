
import { provide } from "vue";
import { computed, defineComponent } from "@vue/runtime-core";
import Nav from "./components/Nav.vue";
import Footer from "./components/Footer.vue";
import axios from "axios";

export default defineComponent({
  name: "App",
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      homeSlogan: "",
      contactInfo: "",
    };
  },
  provide() {
    return {
      homeSlogan: computed(() => this.homeSlogan),
      contactInfo: computed(() => this.contactInfo),
    };
  },
  mounted() {
    (async () => {
      try {
        const res = await axios.post("/api/graphql", {
          query: `{
              home {
                slogan
              }
              contact {
                contactInfo
              }
          }`,
        });
        // this.blogs = res.data.data;
        this.homeSlogan = res.data.data.home.slogan;
        this.contactInfo = res.data.data.contact.contactInfo;
      } catch (e) {
        console.log("err", e);
      }
    })();
  },
  watch: {
    $route(to, from) {
      document.title = to.meta.title || "Reyify";
    },
  },
});
