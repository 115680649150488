
import axios from "axios";
import { defineComponent } from "vue";
import { blog } from "./Home.vue";
import { dateStandard } from "../components/generic.helper";
import marked from "marked";
import Loader from "../components/Loader.vue";

const blogsPerPage = 50;

let searchTimeout: number | null = null;
let curSearch: string | null = null;

export default defineComponent({
  name: "Blogs",
  components: {
    Loader,
  },
  data() {
    return {
      blogs: [] as Array<blog>,
      count: 0,
      location: location,
      search: "",
      whereJSON: undefined as any | null | undefined,
      requesting: false,
    };
  },
  methods: {
    async getData() {
      try {
        if (this.requesting) return;
        let start = 0;
        if (this.blogs.length > 0) {
          if (this.blogs.length === this.count) return;
          start = this.blogs.length;
        }
        this.requesting = true;
        const res = await axios.post("/api/graphql", {
          query: `{
              blogs(limit: ${blogsPerPage}, start: ${start}, sort: "PublishDate:desc" ${
            this.whereJSON
              ? `where: ${JSON.stringify(this.whereJSON).replace(
                  /"([^"]+)":/g,
                  "$1:"
                )}`
              : ""
          }){
                id,
                Title,
                Summary,
                Author,
                PublishDate,
                url,
                tags{
                  TagName,
                  TagURL
                },
                categories{
                  CategoryName,
                  CategoryURL
                }
              }
          }`,
        });
        this.blogs = [...this.blogs, ...(res.data.data.blogs as blog[])];
        for (const blog of this.blogs) {
          blog.PublishDate = dateStandard(new Date(blog.PublishDate));
          blog.Summary = marked(blog.Summary);
        }
        this.requesting = false;
      } catch (e) {
        console.log("err", e);
      }
    },
    async setCount() {
      const res = await axios.post("/api/graphql", {
        query: `{
          blogsConnection${
            this.whereJSON
              ? `(where: ${JSON.stringify(this.whereJSON).replace(
                  /"([^"]+)":/g,
                  "$1:"
                )})`
              : ""
          } {
            aggregate {
              count
            }
          }
        }`,
      });
      this.count = res.data.data.blogsConnection.aggregate.count;
    },
    async updatePath() {
      let splitURL = location.pathname.split("/");
      let newWhere = null;
      this.search = "";
      if (splitURL[2] === "tag") {
        newWhere = { _and: [{ tags: { TagURL: this.$route.params.blogURL } }] };
      } else if (splitURL[2] === "category") {
        newWhere = {
          _and: [{ categories: { CategoryURL: this.$route.params.blogURL } }],
        };
      }
      if (this.whereJSON !== newWhere) {
        this.whereJSON = newWhere;
        this.blogs = [];
        await this.setCount();
        this.getData();
      }
    },
  },
  mounted() {
    this.updatePath();
    window.addEventListener("scroll", this.getData);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.getData);
  },
  watch: {
    $route(to, from) {
      if (to.name === "Blogs") this.updatePath();
    },
    search(val) {
      if (searchTimeout) clearTimeout(searchTimeout);
      searchTimeout = setTimeout(async () => {
        // console.log(val, curSearch);
        if (val === "" && curSearch !== val) {
          // if (whereJSON)
          this.whereJSON = { ...this.whereJSON, ...{ _or: undefined } };
          this.blogs = [];
          await this.setCount();
          this.getData();
        } else if (val !== "") {
          window.history.pushState("", "", "/blog");
          this.whereJSON = {
            ...{
              _or: [
                {
                  Title_contains: val,
                },
                {
                  Content_contains: val,
                },
                {
                  url_contains: val,
                },
              ],
            },
          };
          this.blogs = [];
          await this.setCount();
          this.getData();
        }
        curSearch = val;
      }, 500);
    },
  },
});
