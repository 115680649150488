
import axios from "axios";
import marked from "marked";
import { defineComponent } from "vue";

type Project = {
  Title: string;
  Description: string;
};

export default defineComponent({
  name: "Projects",
  components: {},
  data() {
    return {
      projects: [] as Project[],
    };
  },
  methods: {
    async getData() {
      try {
        const res = await axios.post("/api/graphql", {
          query: `{
              projects {
                Title,
                Description
              }
          }`,
        });
        this.projects = res.data.data.projects;
        for (const project of this.projects) {
          project.Description = marked(project.Description);
        }
      } catch (e) {
        console.log("err", e);
      }
    },
  },
  mounted() {
    this.getData();
  },
});
