<template>
  <div class="home">
    <div class="gradientBG"></div>
    <img
      alt="Reyify Logo"
      src="../assets/logos/logo.svg"
      style="
        width: 50%;
        margin-top: 10%;
        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
      "
    />
    <h2>
      {{ homeSlogan.value }}
    </h2>
    <div class="blogSection">
      <h1>Blog</h1>
      <router-link to="/blog">See More &#8594;</router-link>
      <Loader v-if="requesting" />
      <div class="blogs" v-if="!requesting">
        <div v-for="blog of blogs.blogs" :key="blog.id">
          <router-link class="noHypertext" v-bind:to="'/blog/' + blog.url">
            <span>
              {{ blog.categories.map((x) => x.CategoryName).join(", ") }}
            </span>
            <h3>{{ blog.Title }}</h3>
            <div v-html="blog.Summary.slice(0, 150) + '...'"></div>
            <router-link v-bind:to="'/blog/' + blog.url">Read More</router-link>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import marked from "marked";
import Loader from "../components/Loader.vue";

export type blog = {
  id: string;
  Title: string;
  Summary: string;
  Author: string;
  PublishDate: string;
  url?: string;
  tags: { TagName: string; TagURL: string }[];
  categories: { CategoryName: string; CategoryURL: string }[];
};

export default defineComponent({
  name: "Home",
  components: {
    Loader,
  },
  inject: ["homeSlogan"],
  data() {
    return {
      blogs: {} as { blogs: blog[] },
      requesting: false,
    };
  },
  methods: {
    async getData() {
      try {
        this.requesting = true;
        const res = await axios.post("/api/graphql", {
          query: `{
              blogs(limit: 5, start: 0, sort: "PublishDate:desc"){
                id,
                Title,
                Summary,
                Author,
                PublishDate,
                url,
                tags{
                  TagName,
                  TagURL
                },
                categories{
                  CategoryName,
                  CategoryURL
                }
              }
          }`,
        });
        this.blogs = res.data.data;
        for (const blog of this.blogs.blogs) {
          blog.Summary = marked(blog.Summary);
        }
        this.requesting = false;
      } catch (e) {
        console.log("err", e);
      }
    },
  },
  mounted() {
    this.getData();
  },
});
</script>

<style lang="scss">
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > h2 {
    margin-top: 30px;
  }
}
.gradientBG {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60vh;
  z-index: -1;
  background: linear-gradient(
    -150deg,
    #27efa0,
    #00ebb4,
    #ebf8ff,
    #00e6c6,
    #00e0d6,
    #00dae4,
    #ebf8ff,
    #00d3ef,
    #00cbf7,
    #00c3fb,
    #ebf8ff,
    #00bafb,
    #03b1f8
  );
  animation: gradientBG 30s ease infinite;
  height: 70%;
  background-size: 200% 200%;
  -moz-box-shadow: inset 0 -100px 50px -40px #fff;
  -webkit-box-shadow: inset 0 -100px 50px -40px #fff;
  box-shadow: inset 0 -100px 50px -40px #fff;
}

.blogSection {
  margin-top: 30vh;
  align-self: flex-start;
  padding: 0px 60px;
  text-align: left;
  width: 100%;
  > h1 {
    display: inline-block;
  }
  > a {
    float: right;
  }
  > div {
    margin: 20px 0px;
    > span {
      font-style: italic;
      font-size: smaller;
    }
  }
  > .blogs {
    display: flex;
    flex-flow: row nowrap;
    overflow-x: auto;
    padding: 10px;
    > div {
      &:not(:last-child) {
        margin-right: 80px;
      }
      padding: 20px;
      flex: 1 0 17%;
      border-radius: 10px;
      transition: all 300ms;
      &:hover {
        cursor: pointer;
        box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
          rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
          rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
      }
    }
  }
}

@keyframes gradientBG {
  0% {
    background-position: 75% 0%;
  }
  50% {
    background-position: 26% 100%;
  }
  100% {
    background-position: 75% 0%;
  }
}
</style>