
import { computed, defineComponent } from "vue";
import marked from "marked";

export default defineComponent({
  name: "Contact",
  components: {},
  inject: ["contactInfo"],
  data() {
    return {
      info: computed(() => marked((this.contactInfo as any).value)),
    };
  },
});
