export function dateStandard(date: Date) {
    const options: any = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    };
    try {
        return new Intl.DateTimeFormat("default", options).format(date);
    } catch (error) {
        return "-";
    }
}

export function dateOnly(date: Date) {
    const options: any = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
    };
    try {
        return new Intl.DateTimeFormat("default", options).format(date);
    } catch (error) {
        return "-";
    }
}