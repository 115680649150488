<template>
  <div>
    <h1>Projects</h1>
    <div class="projects">
      <div v-for="project of projects" :key="project">
        <h3>{{ project.Title }}</h3>
        <div v-html="project.Description"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import marked from "marked";
import { defineComponent } from "vue";

type Project = {
  Title: string;
  Description: string;
};

export default defineComponent({
  name: "Projects",
  components: {},
  data() {
    return {
      projects: [] as Project[],
    };
  },
  methods: {
    async getData() {
      try {
        const res = await axios.post("/api/graphql", {
          query: `{
              projects {
                Title,
                Description
              }
          }`,
        });
        this.projects = res.data.data.projects;
        for (const project of this.projects) {
          project.Description = marked(project.Description);
        }
      } catch (e) {
        console.log("err", e);
      }
    },
  },
  mounted() {
    this.getData();
  },
});
</script>

<style lang="scss">
.projects {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 80%;
  margin: 10px auto;
  > div {
    flex-basis: 45%;
    flex-grow: 1;
    margin: 30px;
  }
}
</style>