<template>
  <div>
    <div id="main">
      <Nav />
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script lang="ts">
import { provide } from "vue";
import { computed, defineComponent } from "@vue/runtime-core";
import Nav from "./components/Nav.vue";
import Footer from "./components/Footer.vue";
import axios from "axios";

export default defineComponent({
  name: "App",
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      homeSlogan: "",
      contactInfo: "",
    };
  },
  provide() {
    return {
      homeSlogan: computed(() => this.homeSlogan),
      contactInfo: computed(() => this.contactInfo),
    };
  },
  mounted() {
    (async () => {
      try {
        const res = await axios.post("/api/graphql", {
          query: `{
              home {
                slogan
              }
              contact {
                contactInfo
              }
          }`,
        });
        // this.blogs = res.data.data;
        this.homeSlogan = res.data.data.home.slogan;
        this.contactInfo = res.data.data.contact.contactInfo;
      } catch (e) {
        console.log("err", e);
      }
    })();
  },
  watch: {
    $route(to, from) {
      document.title = to.meta.title || "Reyify";
    },
  },
});
</script>

<style lang="scss">
* {
  margin: 0px;
  //   padding: 0px;
  box-sizing: border-box;
}

body {
  font-family: Roboto, Helvetica Neue, sans-serif;
  color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
  font-weight: 300;
}

h1,
.h1 {
  font-size: 36px;
}
h2,
.h2 {
  font-size: 30px;
}
h3,
.h3 {
  font-size: 24px;
}
h4,
.h4 {
  font-size: 18px;
}
h5,
.h5 {
  font-size: 14px;
}
h6,
.h6 {
  font-size: 12px;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  a {
    text-decoration: none;
    color: #0d6b85;
    transition: all 200ms;
    &:hover {
      color: #1c9b68;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.noHypertext {
  text-decoration: none !important;
  color: #2c3e50 !important;
}

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Roboto&display=swap");
</style>
