<template>
  <div class="footer" :class="{ fixed: isFixed }">
    <div>
      Site by
      <a href="https://dannypoke03.com" target="_blank" rel="noopener"
        >Dannypoke</a
      >
    </div>
    <div>© 2021 Reyify Ltd</div>
    <div>
      <a href="/api/rss"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#fff"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <circle cx="6.18" cy="17.82" r="2.18" />
          <path
            d="M4 4.44v2.83c7.03 0 12.73 5.7 12.73 12.73h2.83c0-8.59-6.97-15.56-15.56-15.56zm0 5.66v2.83c3.9 0 7.07 3.17 7.07 7.07h2.83c0-5.47-4.43-9.9-9.9-9.9z"
          /></svg
        >RSS</a
      >
      &nbsp;
      <router-link to="/contact">Contact</router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
declare let ResizeObserver: any;

export default defineComponent({
  name: "Footer",
  components: {},
  data() {
    return {
      isFixed: false,
    };
  },
  methods: {
    checkFixed() {
      this.isFixed =
        window.innerHeight >
        (document.getElementById("main")?.offsetHeight ?? 0) + 100;
    },
  },
  mounted() {
    this.checkFixed();
    window.addEventListener("resize", () => {
      this.checkFixed();
    });
    const resizeObserver = new ResizeObserver((entries: any) => {
      this.checkFixed();
    });
    resizeObserver.observe(document.getElementById("main"));
  },
});
</script>

<style lang="scss">
.footer {
  margin-top: 100px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 30px;
  background-color: #040e0a;
  color: #fff;
  width: 100%;
  &.fixed {
    position: fixed;
    bottom: 0px;
  }
}
</style>