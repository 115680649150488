<template>
  <Loader v-if="requesting" />
  <div class="blogPage" v-if="!requesting">
    <h1>{{ blog.Title }}</h1>
    <div class="icon-info">
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#000000"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V9h14v10zm0-12H5V5h14v2zM7 11h5v5H7z"
          />
        </svg>
        &nbsp; {{ blog.PublishDate }}
      </span>
    </div>
    <div class="icon-info">
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#000000"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
          />
        </svg>
        &nbsp; {{ blog.Author }}
      </span>
    </div>
    <div>
      Category:
      <router-link
        v-for="category in blog.categories"
        :key="category.CategoryName"
        :to="'/blog/category/' + category.CategoryURL"
      >
        {{ category.CategoryName }}
      </router-link>
      {{ blog.categories?.length > 0 ? "" : "-" }}
    </div>
    <div>
      Tags:
      <router-link
        v-for="tag in blog.tags"
        :key="tag.TagName"
        :to="'/blog/tag/' + tag.TagURL"
      >
        #{{ tag.TagName }}
      </router-link>
      {{ blog.tags?.length > 0 ? "" : "-" }}
    </div>
    <br />
    <div id="contentDiv" v-html="blog.Content"></div>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent } from "vue";
import { dateStandard } from "../components/generic.helper";
import { blog } from "./Home.vue";
import marked from "marked";
import Loader from "../components/Loader.vue";

declare const MathJax: any;

export default defineComponent({
  name: "Blog",
  components: {
    Loader,
  },
  data() {
    return {
      blog: {} as blog & { Content: string },
      blogURL: "",
      requesting: false,
    };
  },
  methods: {
    async getData() {
      try {
        this.requesting = true;
        const res = await axios.post("/api/graphql", {
          query: `{
              blogs(limit: 1, start: 0, where: {url: "${this.blogURL}"}){
                id,
                Title,
                Content,
                url,
                Author,
                PublishDate,
                tags {
                    TagName,
                    TagURL
                },
                categories {
                    CategoryName,
                    CategoryURL
                }
            }
          }`,
        });
        this.blog = res.data.data.blogs[0] ?? null;
        if (this.blog) {
          this.blog.PublishDate = dateStandard(new Date(this.blog.PublishDate));
          this.blog.Content = marked(this.blog.Content);
          document.title = this.blog.Title + " | Reyify";
        }
        MathJax.typesetPromise();
        this.requesting = false;
      } catch (e) {
        console.log("err", e);
      }
    },
  },
  mounted() {
    this.blogURL = this.$route.params.blogURL.toString();
    this.getData();
  },
  updated() {
    this.$nextTick(function () {
      MathJax.typesetPromise();
      let headers = document.querySelectorAll("h1, h2, h3, h4, h5, h6");
      let i = -1;
      for (const header of headers) {
        i++;
        if (i < 1) continue;
        let id =
          header.getAttribute("id") ||
          (header as HTMLHeadingElement).innerText
            .toLowerCase()
            .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>/]/gi, "")
            .replace(/ +/g, "-");
        header.setAttribute("id", id);
        header.innerHTML =
          `<a href="#${id}" aria-hidden="true" class="aal_anchor"><svg aria-hidden="true" class="aal_svg" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fill-rule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>` +
          header.innerHTML;
      }
    });
  },
});
</script>

<style lang="scss">
.blogPage {
  :not(h1) {
    text-align: left;
  }
  max-width: 70%;
  margin: 10px auto;
  div.icon-info > span {
    display: flex;
    justify-content: flex-start;
  }
  p {
    margin-bottom: 10px;
  }
  hr {
    margin: 20px 0px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: black;
    font-weight: 900;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 17.5px;
    border-left: 5px solid #dddddd;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  ul,
  ol {
    margin: 10px;
  }
  .aal_anchor {
    margin-left: -21px;
    margin-right: 5px;
    position: relative;
    scroll-margin-top: 100px;
  }
}
</style>