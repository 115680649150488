<template>
  <div>
    <h1>Contact</h1>
    <div class="contact">
      <div v-html="info"></div>
      <div>
        <img
          src="../assets/logos/ROnly.png"
          alt="Reyify Logo"
          style="width: 40%"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import marked from "marked";

export default defineComponent({
  name: "Contact",
  components: {},
  inject: ["contactInfo"],
  data() {
    return {
      info: computed(() => marked((this.contactInfo as any).value)),
    };
  },
});
</script>

<style lang="scss">
.contact {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 80%;
  margin: 10px auto;
  margin-top: 70px;
  > div {
    flex-basis: 45%;
    flex-grow: 1;
    margin: 30px;
  }
  p {
    text-align: left;
  }
}
</style>