<template>
  <div>
    <h1>Blogs</h1>
    <div>
      <div
        v-if="
          blogs.length > 0 && ['tag'].includes(location.pathname.split('/')[2])
        "
      >
        Filtered by {{ location.pathname.split("/")[2] }}: #{{
          blogs[0].tags.find(
            (x) => x.TagURL === location.pathname.split("/")[3]
          )?.TagName
        }}
      </div>
      <div
        v-if="
          blogs.length > 0 &&
          ['category'].includes(location.pathname.split('/')[2])
        "
      >
        Filtered by {{ location.pathname.split("/")[2] }}:

        {{
          blogs[0].categories.find(
            (x) => x.CategoryURL === location.pathname.split("/")[3]
          )?.CategoryName
        }}
      </div>
      <router-link
        v-if="['tag', 'category'].includes(location.pathname.split('/')[2])"
        v-bind:to="'/blog'"
        >Clear</router-link
      >
    </div>
    <br />
    <input
      v-model="search"
      class="search__input"
      type="text"
      placeholder="Search"
    />
    <Loader v-if="requesting" />
    <div class="blogs" v-if="!requesting">
      <div v-for="blog in blogs" :key="blog.id">
        <router-link class="noHypertext" v-bind:to="'/blog/' + blog.url">
          <router-link
            v-for="category in blog.categories"
            :key="category.CategoryName"
            :to="'/blog/category/' + category.CategoryURL"
          >
            {{ category.CategoryName }}
          </router-link>
          <h2>{{ blog.Title }}</h2>
          <span> {{ blog.PublishDate }} </span>
          <div v-html="blog.Summary.slice(0, 150)"></div>
          <router-link v-bind:to="'/blog/' + blog.url">Read More</router-link>
          <br />
          <router-link
            v-for="tag in blog.tags"
            :key="tag.TagName"
            :to="'/blog/tag/' + tag.TagURL"
          >
            #{{ tag.TagName }}
          </router-link>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent } from "vue";
import { blog } from "./Home.vue";
import { dateStandard } from "../components/generic.helper";
import marked from "marked";
import Loader from "../components/Loader.vue";

const blogsPerPage = 50;

let searchTimeout: number | null = null;
let curSearch: string | null = null;

export default defineComponent({
  name: "Blogs",
  components: {
    Loader,
  },
  data() {
    return {
      blogs: [] as Array<blog>,
      count: 0,
      location: location,
      search: "",
      whereJSON: undefined as any | null | undefined,
      requesting: false,
    };
  },
  methods: {
    async getData() {
      try {
        if (this.requesting) return;
        let start = 0;
        if (this.blogs.length > 0) {
          if (this.blogs.length === this.count) return;
          start = this.blogs.length;
        }
        this.requesting = true;
        const res = await axios.post("/api/graphql", {
          query: `{
              blogs(limit: ${blogsPerPage}, start: ${start}, sort: "PublishDate:desc" ${
            this.whereJSON
              ? `where: ${JSON.stringify(this.whereJSON).replace(
                  /"([^"]+)":/g,
                  "$1:"
                )}`
              : ""
          }){
                id,
                Title,
                Summary,
                Author,
                PublishDate,
                url,
                tags{
                  TagName,
                  TagURL
                },
                categories{
                  CategoryName,
                  CategoryURL
                }
              }
          }`,
        });
        this.blogs = [...this.blogs, ...(res.data.data.blogs as blog[])];
        for (const blog of this.blogs) {
          blog.PublishDate = dateStandard(new Date(blog.PublishDate));
          blog.Summary = marked(blog.Summary);
        }
        this.requesting = false;
      } catch (e) {
        console.log("err", e);
      }
    },
    async setCount() {
      const res = await axios.post("/api/graphql", {
        query: `{
          blogsConnection${
            this.whereJSON
              ? `(where: ${JSON.stringify(this.whereJSON).replace(
                  /"([^"]+)":/g,
                  "$1:"
                )})`
              : ""
          } {
            aggregate {
              count
            }
          }
        }`,
      });
      this.count = res.data.data.blogsConnection.aggregate.count;
    },
    async updatePath() {
      let splitURL = location.pathname.split("/");
      let newWhere = null;
      this.search = "";
      if (splitURL[2] === "tag") {
        newWhere = { _and: [{ tags: { TagURL: this.$route.params.blogURL } }] };
      } else if (splitURL[2] === "category") {
        newWhere = {
          _and: [{ categories: { CategoryURL: this.$route.params.blogURL } }],
        };
      }
      if (this.whereJSON !== newWhere) {
        this.whereJSON = newWhere;
        this.blogs = [];
        await this.setCount();
        this.getData();
      }
    },
  },
  mounted() {
    this.updatePath();
    window.addEventListener("scroll", this.getData);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.getData);
  },
  watch: {
    $route(to, from) {
      if (to.name === "Blogs") this.updatePath();
    },
    search(val) {
      if (searchTimeout) clearTimeout(searchTimeout);
      searchTimeout = setTimeout(async () => {
        // console.log(val, curSearch);
        if (val === "" && curSearch !== val) {
          // if (whereJSON)
          this.whereJSON = { ...this.whereJSON, ...{ _or: undefined } };
          this.blogs = [];
          await this.setCount();
          this.getData();
        } else if (val !== "") {
          window.history.pushState("", "", "/blog");
          this.whereJSON = {
            ...{
              _or: [
                {
                  Title_contains: val,
                },
                {
                  Content_contains: val,
                },
                {
                  url_contains: val,
                },
              ],
            },
          };
          this.blogs = [];
          await this.setCount();
          this.getData();
        }
        curSearch = val;
      }, 500);
    },
  },
});
</script>

<style lang="scss" scoped>
.blogs {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  max-width: 80%;
  margin: 10px auto;
  > div {
    text-align: left;
    flex-basis: 25%;
    flex-grow: 1;
    margin: 30px;
    padding: 20px;
    border-radius: 10px;
    transition: all 300ms;
    h2 {
      color: black;
      font-weight: bold;
    }
    &:hover {
      cursor: pointer;
      box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
        rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
        rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    }
    > span {
      font-style: italic;
      font-size: smaller;
    }
  }
}
.search {
  &__input {
    padding: 12px 24px;

    background-color: transparent;
    transition: transform 250ms ease-in-out;
    font-size: 14px;
    line-height: 18px;

    color: #575756;
    background-color: transparent;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 18px 18px;
    background-position: 95% center;
    border-radius: 50px;
    border: 1px solid #575756;
    transition: all 250ms ease-in-out;
    backface-visibility: hidden;
    transform-style: preserve-3d;

    &::placeholder {
      color: color(#575756 a(0.8));
      text-transform: uppercase;
      letter-spacing: 1.5px;
    }
  }
}
</style>