import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Projects from "../views/Projects.vue";
import Blogs from "../views/Blogs.vue";
import Blog from "../views/Blog.vue";
import Contact from "../views/Contact.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            title: "Reyify"
        }
    },
    {
        path: "/projects",
        name: "Projects",
        component: Projects,
        meta: {
            title: "Projects | Reyify"
        }
    },
    // {
    //     path: "/blog/tag/:blogTag",
    //     name: "Blog",
    //     alias: ['/blog/category/:blogTag'],
    //     component: Blogs,
    //     meta: {
    //         title: "Blogs | Reyify"
    //     }
    // },
    {
        path: "/blog",
        name: "Blogs",
        alias: ['/blog/tag/:blogURL', '/blog/category/:blogURL'],
        component: Blogs,
        meta: {
            title: "Blogs | Reyify"
        }
    },
    {
        path: "/blog/:blogURL",
        name: "Blog",
        component: Blog,
        meta: {
            title: "Blog | Reyify"
        }
    },
    {
        path: "/contact",
        name: "Contact",
        component: Contact,
        meta: {
            title: "Contact | Reyify"
        }
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
