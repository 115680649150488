<template>
  <nav>
    <div>
      <router-link to="/">
        <img
          src="../assets/logos/logo.svg"
          alt="Reyify Logo"
          height="50"
          style="filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.7))"
        />
      </router-link>
    </div>
    <div class="navItems">
      <router-link to="/blog">Blog</router-link>
      <router-link to="/projects">Projects</router-link>
      <router-link to="/contact">Contact</router-link>
    </div>
  </nav>
</template>

<style lang="scss">
nav {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  font-family: "Lato", sans-serif;
  font-size: 1.7rem;
  > div {
    padding: 10px;
  }
  .navItems {
    display: flex;
    justify-content: space-evenly;
    > a {
      display: inline-block;
      position: relative;
      padding: 5px;
      margin: 0.5rem;
      text-decoration: none;
      color: black !important;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.1em;
        background-color: #27efa0;
        opacity: 0;
        transition: opacity 150ms, transform 150ms;
      }
      &:hover {
        text-decoration: none !important;
        color: rgb(51, 51, 51) !important;
      }
      &:hover::after,
      &:focus::after,
      &.router-link-active::after {
        opacity: 1;
        transform: translate3d(0, 0.2em, 0);
        transform: scale(1);
      }
      &::after {
        opacity: 1;
        transform: scale(0);
        transform-origin: center;
      }
    }
  }
}
</style>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Nav",
  components: {},
});
</script>
