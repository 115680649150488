
import { defineComponent } from "vue";
import axios from "axios";
import marked from "marked";
import Loader from "../components/Loader.vue";

export type blog = {
  id: string;
  Title: string;
  Summary: string;
  Author: string;
  PublishDate: string;
  url?: string;
  tags: { TagName: string; TagURL: string }[];
  categories: { CategoryName: string; CategoryURL: string }[];
};

export default defineComponent({
  name: "Home",
  components: {
    Loader,
  },
  inject: ["homeSlogan"],
  data() {
    return {
      blogs: {} as { blogs: blog[] },
      requesting: false,
    };
  },
  methods: {
    async getData() {
      try {
        this.requesting = true;
        const res = await axios.post("/api/graphql", {
          query: `{
              blogs(limit: 5, start: 0, sort: "PublishDate:desc"){
                id,
                Title,
                Summary,
                Author,
                PublishDate,
                url,
                tags{
                  TagName,
                  TagURL
                },
                categories{
                  CategoryName,
                  CategoryURL
                }
              }
          }`,
        });
        this.blogs = res.data.data;
        for (const blog of this.blogs.blogs) {
          blog.Summary = marked(blog.Summary);
        }
        this.requesting = false;
      } catch (e) {
        console.log("err", e);
      }
    },
  },
  mounted() {
    this.getData();
  },
});
